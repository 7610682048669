import * as Dialog from '@radix-ui/react-dialog'
import * as Popover from '@radix-ui/react-popover'
import * as RadioGroup from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import {
  add,
  endOfDay,
  endOfWeek,
  format,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isFirstDayOfMonth as isFirstDayOfMonthFn,
  isSameDay,
  setDate,
  startOfDay,
  startOfWeek,
  sub,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Dispatch, SetStateAction, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import { firstLetterToUppercase } from '../../../lib/upperCase'
import { useDatePicker } from './Root'

export interface DateRange {
  from: Date | null
  to: Date | null
}

interface ContentProps
  extends Popover.PopoverContentProps,
    Dialog.DialogContentProps {
  max?: Date
  min?: Date
  state: DateRange
  setState: Dispatch<SetStateAction<DateRange>>
  handlePaginate?: (pageIndex: number) => void
}

export function Content({
  state,
  setState,
  max,
  min,
  handlePaginate,
  ...rest
}: ContentProps) {
  const [previewMonth, setPreviewMonth] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState<DateRange>(state)
  const { setOpen } = useDatePicker()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const daysInMonth = getDaysInMonth(previewMonth)

  const previousMonthDays = getDay(new Date(previewMonth).setDate(1))

  const nextMonthDays = 42 - (daysInMonth + previousMonthDays)

  const lastMonth = sub(previewMonth, { months: 1 })
  const daysInPreviousMonth = getDaysInMonth(lastMonth)

  const previewDate =
    selectedDate &&
    `${
      selectedDate.from
        ? firstLetterToUppercase(
            format(selectedDate.from, 'MMM dd, yyyy', {
              locale: ptBR,
            }),
          )
        : ''
    } - ${
      selectedDate.to
        ? firstLetterToUppercase(
            format(selectedDate.to, 'MMM dd, yyyy', {
              locale: ptBR,
            }),
          )
        : ''
    }`

  const yearAndMonth = format(previewMonth, 'LLLL uuuu', {
    locale: ptBR,
  })

  function handleSetToday() {
    setSelectedDate({
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
    })
    setPreviewMonth(new Date())
  }

  function handlePreviousMonth() {
    setPreviewMonth((state) =>
      sub(state, {
        months: 1,
      }),
    )
  }

  function handleNextMonth() {
    setPreviewMonth((state) =>
      add(state, {
        months: 1,
      }),
    )
  }

  function handleApplyDate() {
    setState(selectedDate)

    if (handlePaginate) {
      handlePaginate(0)
    }

    setOpen(false)
  }

  function handleSelectedDate(value: string) {
    const { from, to } = selectedDate

    if (from && to) {
      setSelectedDate({
        from: startOfDay(new Date(value)),
        to: null,
      })
    } else if (from) {
      if (isAfter(from, new Date(value))) {
        setSelectedDate(() => {
          return {
            from: startOfDay(new Date(value)),
            to: endOfDay(from),
          }
        })
      } else {
        setSelectedDate(() => {
          return {
            from,
            to: endOfDay(new Date(value)),
          }
        })
      }
    } else {
      setSelectedDate(() => {
        return {
          from: startOfDay(new Date(value)),
          to,
        }
      })
    }
  }

  function handleSelectOneDay(value: string) {
    if (!selectedDate.from) return

    if (isSameDay(new Date(value), selectedDate.from)) {
      setSelectedDate((state) => {
        return {
          from: state.from,
          to: endOfDay(new Date(value)),
        }
      })
    }
  }

  if (isMobile) {
    return (
      <Dialog.Content
        tabIndex={-1}
        className="fixed left-1/2 top-1/2 w-full max-w-sm -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        {...rest}
      >
        <div className="relative z-10 rounded-xl border border-gray-100 bg-white py-5 focus:shadow-none dark:border-gray-750 dark:bg-gray-950">
          <div className="flex items-center justify-between px-6">
            <Button
              variant="ghost"
              className="p-2"
              onClick={handlePreviousMonth}
            >
              <ChevronLeft
                className="h-5 w-5 text-gray-700 dark:text-gray-125"
                strokeWidth={2.5}
              />
            </Button>
            <span className="font-semibold text-gray-700 dark:text-gray-125">
              {firstLetterToUppercase(yearAndMonth)}
            </span>
            <Button variant="ghost" className="p-2" onClick={handleNextMonth}>
              <ChevronRight
                className="h-5 w-5 text-gray-700 dark:text-gray-125"
                strokeWidth={2.5}
              />
            </Button>
          </div>
          <div className="mt-3 flex gap-3 px-6">
            <Input.Root className="w-full">
              <Input.Control
                placeholder="Selecione a data"
                value={
                  selectedDate &&
                  previewDate &&
                  firstLetterToUppercase(previewDate)
                }
              />
            </Input.Root>
            <Button variant="outline" onClick={handleSetToday}>
              Hoje
            </Button>
          </div>
          <RadioGroup.Root onValueChange={handleSelectedDate} asChild>
            <div className="mt-3 grid grid-cols-7 gap-y-1 px-6 pb-5 focus:shadow-none">
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Dom
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Seg
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Ter
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Qua
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Qui
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Sex
              </div>
              <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
                Sab
              </div>
              {Array.from(
                { length: previousMonthDays },
                (_, i) => daysInPreviousMonth - i,
              )
                .reverse()
                .map((item) => (
                  <div
                    key={item}
                    className={clsx(
                      'h-10 w-10 rounded-full px-2 py-2.5 text-center text-sm text-gray-300 transition-colors focus:shadow-none dark:text-gray-350',
                    )}
                  >
                    {item}
                  </div>
                ))}
              {Array.from({ length: daysInMonth }, (_, i) => i + 1).map(
                (item) => {
                  const startOfDate = startOfDay(setDate(previewMonth, item))
                  const endOfDate = endOfDay(setDate(previewMonth, item))

                  const onMaxLimit = max && isAfter(startOfDate, max)

                  const onMinLimit = min && isBefore(startOfDate, min)

                  const isDateAfter = selectedDate.from
                    ? isAfter(startOfDate, selectedDate.from)
                    : false

                  const isDateBefore = selectedDate.to
                    ? isBefore(endOfDate, selectedDate.to)
                    : false

                  const isBetweenSelectedRange = isDateBefore && isDateAfter

                  const isToday = isSameDay(new Date(), startOfDate)

                  const isStartOfWeek = isSameDay(
                    startOfWeek(startOfDate),
                    startOfDate,
                  )

                  const isFirstOfSelectedRange =
                    selectedDate.from &&
                    isSameDay(
                      startOfDate,
                      add(selectedDate.from, {
                        days: 1,
                      }),
                    )

                  const isSelectedDateFrom =
                    selectedDate.from &&
                    isSameDay(startOfDate, selectedDate.from)
                  const isSelectedDateTo =
                    selectedDate.to && isSameDay(startOfDate, selectedDate.to)

                  const isSelectedDateFromAndEndOfWeek =
                    isSelectedDateFrom &&
                    isSameDay(endOfWeek(startOfDate), startOfDate)

                  const isSelectedDateToAndStartOfWeek =
                    isSelectedDateTo &&
                    isSameDay(startOfWeek(startOfDate), startOfDate)

                  const isSelectedDateFromAndToTheSame =
                    selectedDate.from &&
                    selectedDate.to &&
                    isSameDay(selectedDate.from, selectedDate.to)

                  return (
                    <RadioGroup.Item
                      value={startOfDate.toString()}
                      onClick={() => handleSelectOneDay(startOfDate.toString())}
                      disabled={onMaxLimit || onMinLimit}
                      className={clsx(
                        'relative h-10 w-10 cursor-pointer rounded-full text-center text-sm text-gray-700 transition-colors hover:bg-gray-50 hover:font-medium hover:text-gray-800 focus:shadow-base dark:text-gray-125 dark:hover:bg-gray-750',
                        'data-[disabled]:!cursor-default data-[disabled]:!text-gray-300 data-[disabled]:hover:!bg-transparent data-[disabled]:hover:font-normal data-[disabled]:dark:!text-gray-350',
                        {
                          'bg-gray-50 dark:bg-gray-750':
                            isToday && !isBetweenSelectedRange,
                          'rounded-none bg-gray-50 font-medium dark:bg-gray-750':
                            isBetweenSelectedRange,
                          'before:absolute before:-left-2 before:top-0 before:h-full before:w-2 before:bg-gray-50 before:content-[""] dark:before:bg-gray-750':
                            isBetweenSelectedRange &&
                            !isStartOfWeek &&
                            !isFirstOfSelectedRange,
                          'bg-purple-600 font-medium text-white hover:!bg-purple-600 hover:text-white dark:bg-purple-600':
                            isSelectedDateFrom || isSelectedDateTo,
                          'after:absolute after:top-0 after:z-[-1] after:h-full after:w-8 after:-translate-x-2 after:bg-gray-50 after:content-[""] dark:after:bg-gray-750':
                            isSelectedDateFrom &&
                            selectedDate.to &&
                            !isSelectedDateFromAndToTheSame &&
                            !isSelectedDateFromAndEndOfWeek,
                          'before:absolute before:top-0 before:z-[-1] before:h-full before:w-8 before:-translate-x-8 before:bg-gray-50 before:content-[""] dark:before:bg-gray-750':
                            isSelectedDateTo &&
                            !isSelectedDateFromAndToTheSame &&
                            !isSelectedDateToAndStartOfWeek,
                        },
                      )}
                      key={item}
                    >
                      {item}
                    </RadioGroup.Item>
                  )
                },
              )}
              {Array.from({ length: nextMonthDays }, (_, i) => i + 1).map(
                (item) => (
                  <div
                    key={item}
                    className={clsx(
                      'h-10 w-10 rounded-full px-2 py-2.5 text-center text-sm text-gray-300 transition-colors focus:shadow-none dark:text-gray-350',
                    )}
                  >
                    {item}
                  </div>
                ),
              )}
            </div>
          </RadioGroup.Root>
          <div className="flex gap-3 border-t border-gray-100 px-4 pt-4 dark:border-gray-750">
            <Dialog.Close asChild>
              <Button variant="outline" className="flex-1">
                Cancelar
              </Button>
            </Dialog.Close>
            <Button
              onClick={handleApplyDate}
              disabled={!selectedDate.from || !selectedDate.to}
              className="flex-1"
            >
              Aplicar
            </Button>
          </div>
        </div>
      </Dialog.Content>
    )
  }

  return (
    <Popover.Content {...rest} asChild>
      <div className="relative z-10 rounded-xl border border-gray-100 bg-white py-5 focus:shadow-none dark:border-gray-750 dark:bg-gray-950">
        <div className="flex items-center justify-between px-6">
          <Button variant="ghost" className="p-2" onClick={handlePreviousMonth}>
            <ChevronLeft
              className="h-5 w-5 text-gray-700 dark:text-gray-125"
              strokeWidth={2.5}
            />
          </Button>
          <span className="font-semibold text-gray-700 dark:text-gray-125">
            {firstLetterToUppercase(yearAndMonth)}
          </span>
          <Button variant="ghost" className="p-2" onClick={handleNextMonth}>
            <ChevronRight
              className="h-5 w-5 text-gray-700 dark:text-gray-125"
              strokeWidth={2.5}
            />
          </Button>
        </div>
        <div className="mt-3 flex gap-3 px-6">
          <Input.Root className="w-full">
            <Input.Control
              placeholder="Selecione a data"
              value={
                selectedDate &&
                previewDate &&
                firstLetterToUppercase(previewDate)
              }
            />
          </Input.Root>
          <Button variant="outline" onClick={handleSetToday}>
            Hoje
          </Button>
        </div>
        <RadioGroup.Root onValueChange={handleSelectedDate} asChild>
          <div className="mt-3 grid grid-cols-7 gap-y-1 px-6 pb-5 focus:shadow-none">
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Dom
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Seg
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Ter
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Qua
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Qui
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Sex
            </div>
            <div className="h-10 w-10 px-2 py-2.5 text-center text-sm font-medium text-gray-700 dark:text-gray-125">
              Sab
            </div>
            {Array.from(
              { length: previousMonthDays },
              (_, i) => daysInPreviousMonth - i,
            )
              .reverse()
              .map((item) => (
                <div
                  key={item}
                  className={clsx(
                    'h-10 w-10 rounded-full px-2 py-2.5 text-center text-sm text-gray-300 transition-colors focus:shadow-none dark:text-gray-350',
                  )}
                >
                  {item}
                </div>
              ))}
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map(
              (item) => {
                const startOfDate = startOfDay(setDate(previewMonth, item))
                const endOfDate = endOfDay(setDate(previewMonth, item))

                const isFirstDayOfMonth = isFirstDayOfMonthFn(startOfDate)

                const onMaxLimit = max && isAfter(startOfDate, max)

                const onMinLimit = min && isBefore(startOfDate, min)

                const isDateAfter = selectedDate.from
                  ? isAfter(startOfDate, selectedDate.from)
                  : false

                const isDateBefore = selectedDate.to
                  ? isBefore(endOfDate, selectedDate.to)
                  : false

                const isBetweenSelectedRange = isDateBefore && isDateAfter

                const isToday = isSameDay(new Date(), startOfDate)

                const isStartOfWeek = isSameDay(
                  startOfWeek(startOfDate),
                  startOfDate,
                )

                const isFirstOfSelectedRange =
                  selectedDate.from &&
                  isSameDay(
                    startOfDate,
                    add(selectedDate.from, {
                      days: 1,
                    }),
                  )

                const isSelectedDateFrom =
                  selectedDate.from && isSameDay(startOfDate, selectedDate.from)
                const isSelectedDateTo =
                  selectedDate.to && isSameDay(startOfDate, selectedDate.to)

                const isSelectedDateFromAndEndOfWeek =
                  isSelectedDateFrom &&
                  isSameDay(endOfWeek(startOfDate), startOfDate)

                const isSelectedDateToAndStartOfWeek =
                  isSelectedDateTo &&
                  isSameDay(startOfWeek(startOfDate), startOfDate)

                const isSelectedDateFromAndToTheSame =
                  selectedDate.from &&
                  selectedDate.to &&
                  isSameDay(selectedDate.from, selectedDate.to)

                console.log({
                  item,
                  test: item > 1,
                })

                return (
                  <RadioGroup.Item
                    value={startOfDate.toString()}
                    onClick={() => handleSelectOneDay(startOfDate.toString())}
                    disabled={onMaxLimit || onMinLimit}
                    className={clsx(
                      'relative h-10 w-10 cursor-pointer rounded-full text-center text-sm text-gray-700 transition-colors hover:bg-gray-50 hover:font-medium hover:text-gray-800 focus:shadow-base dark:text-gray-125 dark:hover:bg-gray-750',
                      'data-[disabled]:!cursor-default data-[disabled]:!text-gray-300 data-[disabled]:hover:!bg-transparent data-[disabled]:hover:font-normal data-[disabled]:dark:!text-gray-350',
                      {
                        'bg-gray-50 dark:bg-gray-750':
                          isToday && !isBetweenSelectedRange,
                        'rounded-none bg-gray-50 font-medium dark:bg-gray-750':
                          isBetweenSelectedRange,
                        'before:absolute before:-left-2 before:top-0 before:h-full before:w-2 before:bg-gray-50 before:content-[""] dark:before:bg-gray-750':
                          !isFirstDayOfMonth &&
                          isBetweenSelectedRange &&
                          !isStartOfWeek &&
                          !isFirstOfSelectedRange,
                        'bg-purple-600 font-medium text-white hover:!bg-purple-600 hover:text-white dark:bg-purple-600':
                          isSelectedDateFrom || isSelectedDateTo,
                        'after:absolute after:top-0 after:z-[-1] after:h-full after:w-8 after:-translate-x-2 after:bg-gray-50 after:content-[""] dark:after:bg-gray-750':
                          isSelectedDateFrom &&
                          selectedDate.to &&
                          !isSelectedDateFromAndToTheSame &&
                          !isSelectedDateFromAndEndOfWeek,
                        'before:absolute before:top-0 before:z-[-1] before:h-full before:w-8 before:-translate-x-8 before:bg-gray-50 before:content-[""] dark:before:bg-gray-750':
                          isSelectedDateTo &&
                          !isSelectedDateFromAndToTheSame &&
                          !isSelectedDateToAndStartOfWeek,
                      },
                    )}
                    key={item}
                  >
                    {item}
                  </RadioGroup.Item>
                )
              },
            )}
            {Array.from({ length: nextMonthDays }, (_, i) => i + 1).map(
              (item) => (
                <div
                  key={item}
                  className={clsx(
                    'h-10 w-10 rounded-full px-2 py-2.5 text-center text-sm text-gray-300 transition-colors focus:shadow-none dark:text-gray-350',
                  )}
                >
                  {item}
                </div>
              ),
            )}
          </div>
        </RadioGroup.Root>
        <div className="flex gap-3 border-t border-gray-100 px-4 pt-4 dark:border-gray-750">
          <Popover.Close asChild>
            <Button variant="outline" className="flex-1">
              Cancelar
            </Button>
          </Popover.Close>
          <Button
            onClick={handleApplyDate}
            disabled={!selectedDate.from || !selectedDate.to}
            className="flex-1"
          >
            Aplicar
          </Button>
        </div>
      </div>
    </Popover.Content>
  )
}
