import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { getAuthenticatedUser } from '../../api/get-authenticated-user'
import { api } from '../../lib/axios'
import { queryClient } from '../../lib/react-query'
import { ChangePasswordModal } from './change-password-modal'
import { InactiveUser } from './inactive-user'
import { MobileSideBar } from './mobile-sidebar'
import { SideBar } from './sidebar'

export function AppLayout() {
  const navigate = useNavigate()
  const location = useLocation()

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  useEffect(() => {
    const interceptorId = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error)) {
          const status = error.response?.status
          const code = error.response?.data.code

          if (status === 401 && code === 'UNAUTHORIZED') {
            queryClient.resetQueries()
            navigate('/sign-in')
          } else {
            throw error
          }
        }
      },
    )

    return () => {
      api.interceptors.response.eject(interceptorId)
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/tickets/my')
    }
  }, [location])

  if (authenticatedUser) {
    if (!authenticatedUser.user.active) {
      return <InactiveUser />
    }
  }

  return (
    <div className="md:flex">
      {authenticatedUser?.user.firstAccess && <ChangePasswordModal />}
      {isMobile ? <MobileSideBar /> : <SideBar />}
      <div className="min-h-screen w-full dark:bg-gray-950 md:max-h-screen md:overflow-y-auto">
        <div
          className={clsx('md:flex-1 md:p-8', {
            'py-8': !location.pathname.includes('comments'),
          })}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}
