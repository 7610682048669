import { api } from '../lib/axios'

export interface RegisterTeamBody {
  name: string
}

export async function registerTeam({ name }: RegisterTeamBody) {
  console.log(name)
  await api.post('/teams', {
    name,
  })
}
