import { TableHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export function TableCell({
  children,
  ...props
}: TableHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      {...props}
      className={twMerge(
        'whitespace-nowrap border-b border-gray-100 px-6 py-3.5 text-sm font-normal text-gray-600 group-hover/tableRow:bg-gray-50 dark:border-gray-750 dark:text-gray-375 group-hover/tableRow:dark:bg-gray-750 md:first:border-l md:last:border-r md:group-last/tableRow:first:rounded-bl-lg md:group-last/tableRow:last:rounded-br-lg',
        props.className,
      )}
    >
      {children}
    </th>
  )
}
