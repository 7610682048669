import { createBrowserRouter } from 'react-router-dom'

import { AppLayout } from './pages/_layouts/app'
import { ForgotPassword } from './pages/auth/forgot-password'
import { ResetPassword } from './pages/auth/reset-password'
import { SignIn } from './pages/auth/sign-in'
import { Error } from './pages/error'
import { Incidents } from './pages/incidents/page'
import { Settings } from './pages/incidents/settings/page'
import { NotFound } from './pages/not-found'
import { Alerts } from './pages/services/alerts/page'
import { Services } from './pages/services/general/page'
import { ServicesLayout } from './pages/services/layout'
import { Status } from './pages/services/status/page'
import SettingsLayout from './pages/settings/layout'
import { Teams } from './pages/settings/teams/page'
import { Users } from './pages/settings/users/page'
import { Assets } from './pages/tickets/[slug]/assets/page'
import { MobileMessages } from './pages/tickets/[slug]/comments/page'
import { TicketLayout } from './pages/tickets/[slug]/layout'
import { Overview } from './pages/tickets/[slug]/overview/page'
import { Participants } from './pages/tickets/[slug]/participants/page'
import { Categories } from './pages/tickets/categories/page'
import { TicketsLayout } from './pages/tickets/layout'
import { My } from './pages/tickets/my/page'
import { Status as TicketStatus } from './pages/tickets/status/page'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        path: '/tickets',
        element: <TicketsLayout />,
        children: [
          {
            path: '/tickets/my',
            element: <My />,
          },
          {
            path: '/tickets/status',
            element: <TicketStatus />,
          },
          {
            path: '/tickets/categories',
            element: <Categories />,
          },
        ],
      },
      {
        path: '/tickets/:ticketId',
        element: <TicketLayout />,
        children: [
          {
            path: '/tickets/:ticketId/overview',
            element: <Overview />,
          },
          {
            path: '/tickets/:ticketId/participants',
            element: <Participants />,
          },
          {
            path: '/tickets/:ticketId/assets',
            element: <Assets />,
          },
          {
            path: '/tickets/:ticketId/comments',
            element: <MobileMessages />,
          },
        ],
      },
      {
        path: '/incidents',
        children: [
          {
            path: '/incidents',
            element: <Incidents />,
          },
          {
            path: '/incidents/settings',
            element: <Settings />,
          },
        ],
      },
      {
        path: '/settings',
        element: <SettingsLayout />,
        children: [
          {
            path: '/settings/teams',
            element: <Teams />,
          },
          {
            path: '/settings/users',
            element: <Users />,
          },
        ],
      },
      {
        path: '/services',
        element: <ServicesLayout />,
        children: [
          {
            path: '/services/general',
            element: <Services />,
          },
          {
            path: '/services/alerts',
            element: <Alerts />,
          },
          {
            path: '/services/status',
            element: <Status />,
          },
        ],
      },
    ],
  },
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  { path: '*', element: <NotFound /> },
])
