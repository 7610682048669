/* eslint-disable @typescript-eslint/no-explicit-any */

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'
import { Controller } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

export const Checkbox = (props: any) => (
  <Controller
    defaultValue={false}
    {...props}
    render={({ field }) => (
      <CheckboxPrimitive.Root
        className={twMerge(
          "flex h-4 w-4 items-center justify-center rounded-[4px] border border-gray-200 bg-transparent text-white transition-colors data-[state='checked']:border-purple-600 data-[state='checked']:bg-purple-600 dark:border-gray-725",
          props.className,
        )}
        {...field}
        id={props.id}
        value={undefined}
        checked={field.value}
        onCheckedChange={field.onChange}
      >
        <CheckboxPrimitive.Indicator>
          <Check className="h-3 w-3" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    )}
  />
)
