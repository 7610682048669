/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { HardDrive, Plus, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getCategories } from '../../api/get-categories'
import { getIncidentModels } from '../../api/get-incident-models'
import { registerIncident } from '../../api/register-incident'
import { Button } from '../../components/Button'
import * as Input from '../../components/Form/Input'
import * as Select from '../../components/Form/Select'
import { Textarea } from '../../components/Form/Textarea'
import { Toast } from '../../components/Toast'
import { Tooltip } from '../../components/Tooltip'
import { queryClient } from '../../lib/react-query'
import { cities } from '../tickets/my/create-ticket-modal'

export const createIncidentSchema = z.object({
  title: z.string().min(3, 'Título tem que conter no mínimo 3 caracteres'),
  city: z.string().min(1, 'Cidade é obrigatório'),
  category: z.string().min(1, 'Categoria é obrigatório'),
  term: z.string().optional(),
  model: z.string().uuid().optional(),
  script: z.string().min(3, 'Script tem que conter no mínimo 3 caracteres'),
  level: z.string().min(1, 'Nível é obrigatório'),
})

export type CreateIncidentProps = z.infer<typeof createIncidentSchema>

export interface Model {
  name: string
  value: string
  script: string
}

export function CreateIncidentModal() {
  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<CreateIncidentProps>({
    resolver: zodResolver(createIncidentSchema),
  })

  const levelsData = [
    {
      level: 1,
      name: '0-32',
      amount: 0,
      color: '#333741',
    },
    {
      level: 2,
      name: '32-64',
      amount: 0,
      color: '#6941C6',
    },
    {
      level: 3,
      name: '64-256',
      amount: 0,
      color: '#7F56D9',
    },
    {
      level: 4,
      name: '256-500',
      amount: 0,
      color: '#9E77ED',
    },
    {
      level: 5,
      name: '500+',
      amount: 0,
      color: '#B692F6',
    },
  ]

  const { data: modelsResult } = useQuery({
    queryKey: ['incident-models'],
    queryFn: () =>
      getIncidentModels({
        pageIndex: 0,
        perPage: 9999,
      }),
  })

  const { mutateAsync: registerIncidentFn } = useMutation({
    mutationKey: ['register-incident'],
    mutationFn: registerIncident,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['incidents'] })
      queryClient.refetchQueries({ queryKey: ['incidents-per-level-metric'] })
      queryClient.refetchQueries({ queryKey: ['incidents-per-city-metric'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Incidente registrado com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar registrar o incidente, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateIncident(data: CreateIncidentProps) {
    const { title, city, level, model, script, term, category } = data

    registerIncidentFn({
      title,
      city,
      level,
      modelId: model,
      script,
      term,
      category: {
        id: category,
      },
    })
  }

  const [term, model] = watch(['term', 'model'])

  const { data: categoriesResult } = useQuery({
    queryKey: ['incidents-categories'],
    queryFn: () =>
      getCategories({
        pageIndex: 0,
        perPage: 9999,
        type: 'incidents',
      }),
  })

  useEffect(() => {
    if (!modelsResult) return
    if (!model) return
    setValue(
      'script',
      modelsResult.models.find((item) => item.id === model)?.description || '',
    )
  }, [model, modelsResult, setValue])

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
        reset()
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="mt-4 flex items-center gap-1.5 md:mt-0">
          <Plus className="h-5 w-5" />
          Cadastrar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950 md:max-w-2xl"
        >
          <div className="flex items-start border-b border-gray-100 px-4 py-5 dark:border-gray-750 md:p-6">
            <div className="hidden rounded-md border border-gray-100 p-3 text-lg dark:border-gray-750 md:block">
              <HardDrive className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <div className="md:ml-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Registrar Incidente
              </Dialog.Title>
              <Dialog.Description className="text-sm text-gray-600 dark:text-gray-375">
                Descreva as informações do incidente abaixo
              </Dialog.Description>
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="ml-auto rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <form
            id="createIncident"
            className="max-h-[60vh] overflow-y-auto py-6 md:max-h-none md:pb-0 md:pt-8"
            onSubmit={handleSubmit(handleCreateIncident)}
          >
            <div className="flex flex-col gap-4 px-6">
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Incidente*
                </label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <Input.Root>
                      <Input.Control
                        {...field}
                        placeholder="e.g. CTO, SLOT, PON"
                      />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label
                  htmlFor="city"
                  className="text-sm font-medium text-gray-700 dark:text-gray-375"
                >
                  Cidade*
                </label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Selecione a cidade" />
                      </Select.Trigger>

                      <Select.Content>
                        {cities.map((city) => (
                          <Select.Item key={city} value={city}>
                            <Select.ItemText>{city}</Select.ItemText>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-700 dark:text-gray-375"
                >
                  Categoria*
                </label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Selecione a categoria" />
                      </Select.Trigger>

                      <Select.Content>
                        {categoriesResult &&
                          categoriesResult.categories.map((item) => (
                            <Select.Item key={item.id} value={item.id}>
                              <Select.ItemText>{item.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Prazo
                </label>
                <Controller
                  name="term"
                  control={control}
                  render={({ field }) => (
                    <Input.Root>
                      <Input.Control
                        {...field}
                        type="time"
                        className={clsx({
                          'text-gray-300 dark:text-gray-350': !term,
                          'text-gray-900 dark:text-gray-25': term,
                        })}
                      />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Modelo*
                </label>
                <Controller
                  name="model"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Selecione o modelo do script" />
                      </Select.Trigger>

                      <Select.Content>
                        {modelsResult &&
                          modelsResult.models.map((item) => (
                            <Select.Item key={item.name} value={item.id}>
                              <Select.ItemText>{item.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label
                  htmlFor="script"
                  className="text-sm font-medium text-gray-700 dark:text-gray-375"
                >
                  Script*
                </label>
                <Controller
                  name="script"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Textarea
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="e.g. incidente, prazo"
                    />
                  )}
                />
              </div>
              <div className="mt-2 grid grid-cols-2 items-center">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Nível*
                </label>
                <Controller
                  name="level"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup.Root
                      className="ml-auto flex gap-4 focus:shadow-none"
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      {levelsData.map((item) => (
                        <Tooltip
                          key={item.level}
                          description={`Nível ${item.level}: ${item.name}`}
                          asChild
                        >
                          <RadioGroup.Item
                            value={String(item.level)}
                            className="h-4 w-4 rounded-full data-[state=checked]:shadow-base dark:data-[state=checked]:shadow-gray"
                            style={{ background: item.color }}
                          >
                            <RadioGroup.Indicator />
                          </RadioGroup.Item>
                        </Tooltip>
                      ))}
                    </RadioGroup.Root>
                  )}
                />
              </div>
            </div>
          </form>
          <div className="mt-6 flex flex-col-reverse gap-3 border-t border-gray-100 p-4 dark:border-gray-750 md:flex-row">
            <Dialog.Cancel asChild>
              <Button variant="outline" type="button" className="flex-1">
                Cancelar
              </Button>
            </Dialog.Cancel>
            <Button
              form="createIncident"
              type="submit"
              className="flex-1"
              disabled={!isValid}
            >
              Criar
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
