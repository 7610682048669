import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Edit2, Layers, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getCategories } from '../../../api/get-categories'
import { getEnabledTeamsTicketForm } from '../../../api/get-enabled-teams-ticket-form'
import { getTicket } from '../../../api/get-ticket'
import { getTicketStatus } from '../../../api/get-ticket-status'
import { updateTicket } from '../../../api/update-ticket'
import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Textarea } from '../../../components/Form/Textarea'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'
import { cities, priorities } from '../my/create-ticket-modal'
import { MapLocationModal } from '../my/map-location-modal'

export const updateTicketSchema = z.object({
  title: z.string().min(1, 'Título é obrigatório.'),
  team: z.string().uuid().min(1, 'Equipe é obrigatório.'),
  location: z.string(),
  priority: z.enum(['low', 'medium', 'high'], {
    required_error: 'Prioridade é obrigatório.',
  }),
  description: z.string().min(1, 'Descrição é obrigatório.'),
  status: z.string().uuid().min(1, 'Status é obrigatório.'),
  category: z.string().uuid().min(1, 'Categoria é obrigatório.'),
  coordinates: z.string().optional(),
})

type UpdateTicketProps = z.infer<typeof updateTicketSchema>

export function EditTicketModal() {
  const [open, setOpen] = useState<boolean>()

  const { ticketId } = useParams()

  const { data: result } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  const { data: teamsResult } = useQuery({
    queryKey: ['enabled-ticket-teams'],
    queryFn: getEnabledTeamsTicketForm,
  })

  const { data: statusResult } = useQuery({
    queryKey: ['ticket-status'],
    queryFn: () =>
      getTicketStatus({
        pageIndex: 0,
        perPage: 9999,
      }),
  })

  const ticket = result?.ticket
  const isFinished = ticket?.status.type === 'finished'

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<UpdateTicketProps>({
    values: {
      title: ticket?.title ?? '',
      description: ticket?.description ?? '',
      location: ticket?.location ?? 'none',
      team: ticket?.team.id ?? '',
      priority: ticket?.priority ?? 'low',
      status: ticket?.status.id ?? '',
      category: ticket?.category.id ?? '',
      ...(ticket?.coordinates && {
        coordinates: ticket?.coordinates,
      }),
    },
    resolver: zodResolver(updateTicketSchema),
  })

  const teamId = watch('team')

  const { data: categoriesResult } = useQuery({
    queryKey: ['categories', teamId],
    queryFn: () =>
      getCategories({
        type: 'tickets',
        pageIndex: 0,
        perPage: 9999,
        ...(teamId && {
          teamId,
        }),
      }),
  })

  const { mutateAsync: updateTicketFn } = useMutation({
    mutationKey: ['update-ticket'],
    mutationFn: updateTicket,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['ticket', ticketId] })
      queryClient.refetchQueries({ queryKey: ['ticket-activity', ticketId] })

      setOpen(false)

      toast.custom((t) => (
        <Toast
          t={t}
          title="Chamado atualiazado com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar este chamado, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleUpdateTicket(data: UpdateTicketProps) {
    const {
      title,
      description,
      location,
      priority,
      team,
      status,
      category,
      coordinates,
    } = data

    updateTicketFn({
      ticket: {
        id: ticketId ?? '',
      },
      title,
      description,
      coordinates,
      location: location === 'none' ? null : location,
      priority,
      team: {
        id: team,
      },
      status: {
        id: status,
      },
      category: {
        id: category,
      },
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
        reset()
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="flex items-center gap-1.5">
          <Edit2 className="h-4 w-4" /> Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950 md:max-w-2xl"
        >
          <div className="flex items-start border-b border-gray-100 px-4 py-5 dark:border-gray-750 md:p-6">
            <div className="hidden rounded-md border border-gray-100 p-3 text-lg dark:border-gray-750 md:block">
              <Layers className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <div className="md:ml-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Edite seu chamado
              </Dialog.Title>
              <Dialog.Description className="text-sm text-gray-600 dark:text-gray-375">
                Abaixo você pode visualizar e editar todos os detalhes do seu
                chamado.
              </Dialog.Description>
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="ml-auto rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <div className="pt-5">
            <form
              onSubmit={handleSubmit(handleUpdateTicket)}
              className="max-h-[70vh] overflow-y-auto md:max-h-none"
            >
              <div className="flex flex-col gap-4 px-4 md:px-6">
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Título*
                  </label>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input.Root>
                        <Input.Control {...field} disabled={isFinished} />
                      </Input.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Descrição*
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Textarea
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className="max-h-[166px]"
                        disabled={isFinished}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Cidade*
                  </label>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={isFinished}
                      >
                        <Select.Trigger>
                          <Select.Value />
                        </Select.Trigger>

                        <Select.Content>
                          <Select.Item key="none" value="none">
                            <Select.ItemText>Nenhuma</Select.ItemText>
                          </Select.Item>
                          {cities.map((city) => (
                            <Select.Item key={city} value={city}>
                              <Select.ItemText>{city}</Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Equipe*
                  </label>
                  <Controller
                    name="team"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={isFinished}
                      >
                        <Select.Trigger>
                          <Select.Value />
                        </Select.Trigger>

                        <Select.Content>
                          {teamsResult &&
                            teamsResult.teams?.map(({ id, name }) => (
                              <Select.Item key={id} value={id}>
                                <Select.ItemText>{name}</Select.ItemText>
                              </Select.Item>
                            ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
                <div className="my-4 hidden h-px w-full bg-gray-100 dark:bg-gray-750 md:block" />
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Prioridade*
                  </label>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={isFinished}
                      >
                        <Select.Trigger>
                          <Select.Value />
                        </Select.Trigger>

                        <Select.Content>
                          {priorities.map(({ value, name }) => (
                            <Select.Item key={value} value={value}>
                              <Select.ItemText>{name}</Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>

                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Categoria*
                  </label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={isFinished}
                      >
                        <Select.Trigger>
                          <Select.Value />
                        </Select.Trigger>

                        <Select.Content>
                          {categoriesResult &&
                            categoriesResult.categories?.map(({ id, name }) => (
                              <Select.Item key={id} value={id}>
                                <Select.ItemText>{name}</Select.ItemText>
                              </Select.Item>
                            ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Status*
                  </label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <Select.Trigger>
                          <Select.Value />
                        </Select.Trigger>

                        <Select.Content>
                          {statusResult &&
                            statusResult.status?.map(({ id, name }) => (
                              <Select.Item key={id} value={id}>
                                <Select.ItemText>{name}</Select.ItemText>
                              </Select.Item>
                            ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
                {ticket?.coordinates && (
                  <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                      Localização*
                    </label>
                    <Controller
                      name="coordinates"
                      control={control}
                      render={({ field }) => (
                        <MapLocationModal
                          value={field.value}
                          onValueChange={field.onChange}
                          disabled={isFinished}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="mt-6 flex flex-col-reverse gap-3 border-t border-gray-100 p-4 dark:border-gray-750 md:flex-row">
                <Dialog.Cancel asChild>
                  <Button variant="outline" className="flex-1" type="button">
                    Cancelar
                  </Button>
                </Dialog.Cancel>
                <Button
                  type="submit"
                  className="flex-1"
                  variant="primary"
                  disabled={!isDirty}
                >
                  Salvar
                </Button>
              </div>
            </form>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
