import { api } from '../lib/axios'

export interface UpdateTicketBody {
  ticket: {
    id: string
  }
  title: string
  description: string
  coordinates?: string
  location: string | null
  priority: 'low' | 'medium' | 'high'
  team: {
    id: string
  }
  status: {
    id: string
  }
  category: {
    id: string
  }
}

export async function updateTicket({
  ticket,
  title,
  description,
  location,
  priority,
  team,
  status,
  category,
  coordinates,
}: UpdateTicketBody) {
  await api.patch('/tickets', {
    ticket,
    title,
    description,
    location,
    priority,
    team,
    status,
    category,
    coordinates,
  })
}
